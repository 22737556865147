import styles from "./OrderForm.module.css";
const OrderForm = (props) => {
  const addOne = () => {
    let val = +props.count + 1;
    props.updateCount(val);
    props.addItem();
  };
  const removeOne = () => {
    let val = props.count - 1;
    val = val < 0 ? 0 : val;
    props.updateCount(val);
    props.removeItem();
  };
  return (
    <>
      <span className={styles.qtyPrice}>{props.qtyPrice}</span>
      <span>
        <span className={`input-group ${styles["add-button-group"]}`}>
          <button
            className={`btn btn-sm btn-outline-secondary center ${styles.leftButton}`}
            type="button"
            onClick={removeOne}
          >
            <div>-</div>
          </button>
          <span
            className={`${styles.totalDisplay} ${styles.center} btn-outline-secondary`}
          >
            <div>{props.count}</div>
          </span>
          <button
            className={`${styles.rightButton} btn btn-sm btn-outline-secondary center`}
            type="button"
            onClick={addOne}
          >
            <div>+</div>
          </button>
        </span>
      </span>
    </>
  );
};

export default OrderForm;
