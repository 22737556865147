import { useContext } from "react";
import CartContext from "../../Store/cart-context";
import Pricing from "./Pricing";

const MenuItem = (props) => {
  const { filterType, imageLink, itemPrices, itemIngredients } = props;
  const ctx = useContext(CartContext);
  const addItemToCartHandler = (item, priceWeight) => {
    ctx.addItem(props.item, priceWeight);
  };
  const removeItemToCartHandler = (item, priceWeight) => {
    ctx.removeItem(props.item, priceWeight);
  };
  const prices = itemPrices.map((priceWeight, idx) => {
    const key = `${props.itemId}.${idx}`;
    priceWeight["key"] = key;
    return (
      <Pricing
        addItem={addItemToCartHandler.bind(null, props.item, priceWeight)}
        removeItem={removeItemToCartHandler.bind(null, props.item, priceWeight)}
        deets={priceWeight}
        idx={idx}
        key={key}
        itemName={props.itemName}
      />
    );
  });
  return (
    <div className={`col-lg-6 menu-item filter-${filterType}`}>
      <img src={imageLink} className="menu-img" alt="" />
      <a className="show-when-small">{props.itemName}</a>
      {prices}
      <div className="menu-ingredients">{itemIngredients}</div>
    </div>
  );
};
export default MenuItem;
