import { useContext } from "react";
import CartContext from "../../Store/cart-context";
import styles from "./Cart.module.css";
const Cart = () => {
  const ctx = useContext(CartContext);

  const items = [...Object.values(ctx.items)].map((item) => (
    <CartItem {...item} />
  ));
  const ct = [...Object.values(ctx.items)].reduce(
    (acc, curr) => acc + +curr.amount,
    0
  );
  if (0 === ctx.totalAmount) {
    return <></>;
  }

  return (
    <div className={`${styles.cart} mb-4`}>
      <h3>Your Order</h3>
      <div className={styles["cart-header"]}>
        <span>Item</span>
        <span>Price</span>
        <span>Qty</span>
        <span>Total</span>
      </div>
      <span>{items}</span>
      <div className={styles["cart-header"]}>
        <span>Order Total:</span>
        <span></span>
        <span>{ct}</span>
        <span>₵{ctx.totalAmount}</span>
      </div>
    </div>
  );
};

const CartItem = (props) => {
  return (
    <div className={styles.items}>
      <span className={styles.name}>
        {props.itemName} {props.weight}
      </span>
      {/* <span className={styles.name}></span> */}
      <span className={styles.amount}>{props.price}</span>
      <span className={styles.amount}>{props.amount}</span>
      <span className={styles.amount}>
        {(+props.amount * +props.price).toFixed(2)}
      </span>
    </div>
  );
};

export default Cart;
