import React, { useContext, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import Cart from "./Cart/Cart";
import CartContext from "../Store/cart-context";
function Contact() {
  const recaptchaRef = useRef(null);

  const [values, changeValues] = useState({
    // name: "josh",
    // email: "josdfa.adfs@asdf.com",
    // phone: 23423234324,
    // subject: "this subject",
    // message: "this message",
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    disableSubmit: "disabled",
    onSubmitTipEnabeled: false,
    submitSuccess: false,
    submitFail: false,
  });

  const enableSubmit = () => {
    changeValues((prevValues) => {
      return { ...prevValues, disableSubmit: "", onSubmitTipEnabeled: true };
    });
  };

  const disableSubmit = () => {
    changeValues((prevValues) => {
      return {
        ...prevValues,
        disableSubmit: "disabled",
        onSubmitTipEnabeled: false,
      };
    });
  };
  const submitSuccess = () => {
    changeValues((prevValues) => {
      return {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        disableSubmit: "disabled",
        onSubmitTipEnabeled: false,
        submitSuccess: true,
        submitFail: false,
      };
    });
  };
  const submitFail = () => {
    changeValues((prevValues) => {
      return {
        ...prevValues,
        disableSubmit: "disabled",
        onSubmitTipEnabeled: false,
        submitSuccess: false,
        submitFail: true,
      };
    });
  };
  const ctx = useContext(CartContext);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const captchaToken = await recaptchaRef.current.getValue();
    const form = new FormData(event.target);
    recaptchaRef.current.reset();
    disableSubmit();
    // Pass this token to your server for validation...

    const res = await axios
      .post("assets/contact.php", {
        name: form.get("name"),
        email: form.get("email"),
        phone: form.get("phone"),
        subject: form.get("subject"),
        message: form.get("message"),
        items: ctx.items,
        captchaToken,
      })
      .catch(() => {
        submitFail();
      });
    const mailStatus = res.data;
    // console.log(mailStatus);
    if (!mailStatus.error) {
      submitSuccess();
    } else {
      submitFail();
    }
    return false;
  };
  return (
    <>
      {/*<!-- ======= Contact Section ======= -->*/}
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Contact</h2>
            <p>Contact Us</p>
          </div>
        </div>

        <div className="container mb-1" data-aos="fade-up">
          <div className="row ">
            <div className="col-lg-4">
              <div className="info">
                <Cart />
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>Dome, Accra, Ghana</p>
                </div>
                <div className="open-hours">
                  <i className="bi bi-clock"></i>
                  <h4>Ordering:</h4>
                  <p>
                    Please fill out the contact form and we will get back to
                    your about the order process, deliveries and pickup.
                  </p>
                </div>
                {/*


                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>info@example.com</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+1 5589 55488 55s</p>
                </div>
*/}
              </div>
            </div>

            <div className="col-lg-8 mt-5 mt-lg-0">
              <form onSubmit={handleSubmit} className="php-email-form">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      defaultValue={values.name}
                      value={values.name}
                      onChange={(e) =>
                        changeValues({
                          ...values,
                          [e.target.id]: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      defaultValue={values.email}
                      value={values.email}
                      onChange={(e) =>
                        changeValues({
                          ...values,
                          [e.target.id]: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      id="phone"
                      placeholder="Your Phone"
                      defaultValue={values.phone}
                      value={values.phone}
                      onChange={(e) =>
                        changeValues({
                          ...values,
                          [e.target.id]: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      defaultValue={values.subject}
                      value={values.subject}
                      onChange={(e) =>
                        changeValues({
                          ...values,
                          [e.target.id]: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="8"
                    id="message"
                    placeholder="Message"
                    defaultValue={values.message}
                    value={values.message}
                    onChange={(e) =>
                      changeValues({
                        ...values,
                        [e.target.id]: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <p
                    className="badge badge-pill badge-primary"
                    style={{
                      backgroundColor: "#28a745",
                      fontSize: "13pt",
                      display: values.submitSuccess ? "" : "none",
                    }}
                  >
                    Your form has been submitted successfully.
                  </p>
                </div>
                <div className="text-center">
                  <p
                    className="badge badge-pill badge-danger"
                    style={{
                      backgroundColor: "#dc3545",
                      fontSize: "13pt",
                      display: values.submitFail ? "" : "none",
                    }}
                  >
                    There has been an error, please try again
                  </p>
                </div>
                <div className="mb-3 captcha-container">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={"6Le7OusfAAAAAAcQZum6-ffOmf7W7J-3tIc-OGOD"}
                    onChange={enableSubmit}
                    onErrored={disableSubmit}
                    onExpired={disableSubmit}
                  />
                </div>

                <div className="text-center">
                  <span data-tip data-for="formSubmit">
                    <button
                      type="submit"
                      data-callback="onSubmit"
                      data-action="submit"
                      disabled={values.disableSubmit}
                    >
                      Send Message
                    </button>
                    <ReactTooltip
                      id="formSubmit"
                      tip="hello world"
                      effect="float"
                      type="light"
                      disable={values.onSubmitTipEnabeled}
                    >
                      Kindly verify that you are not a robot
                    </ReactTooltip>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div data-aos="fade-up">
          <iframe
            title="snavielakvdfaldvf"
            style={{ border: "0", width: "100%", height: "350px" }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127066.75948245761!2d-0.24994163691773413!3d5.591204528476066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9084b2b7a773%3A0xbed14ed8650e2dd3!2sAccra!5e0!3m2!1sen!2sgh!4v1645114967616!5m2!1sen!2sgh"
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        </div>
      </section>
      {/*<!-- End Contact Section -->*/}
    </>
  );
}

export default Contact;
