import { useState } from "react";
import Header from "../Components/Header.js";
import Footer from "../Components/Footer.js";

import Hero from "../Components/Hero.js";
// import About from '../Components/About.js';
// import WhyUs from '../Components/WhyUs.js';
import Menu from "../Components/Menu/Menu.js";
// import Specials from '../Components/Specials.js';
// import Events from '../Components/Events.js';
// import BookATable from '../Components/BookATable.js';
import Testimonials from "../Components/Testimonials.js";
// import Gallery from '../Components/Gallery.js';
// import Chefs from '../Components/Chefs.js';
import Contact from "../Components/Contact.js";

const Home = () => {
  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  );
};

const Body = (props) => {
  // <About/>
  // <WhyUs/>
  // <Menu/>
  // <Specials/>
  // <Events/>
  // <BookATable/>
  // <Testimonials/>
  // <Gallery/>
  // <Chefs/>
  // <Contact/>

  // <Testimonials/>
  //   <Menu/>
  //   <Contact/>
  return (
    <>
      <Hero />
      <main id="main">
        <Testimonials />
        <Menu />
        <Contact />
      </main>
    </>
  );
};

export default Home;
