import { createContext } from "react";
const CartContext = createContext({
  items: [],
  totalItems: 0,
  totalAmount: 0,
  addItem: (item) => {},
  removeItem: (id) => {},
  updateItem: (item) => {},
});
export default CartContext;
