import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import logo from "./logo.svg";
import "./App.css";

import Home from "./Pages/Home";
import CartProvider from "./Store/cartProvider";

// https://www.fullstacktutorials.com/deploy-react-app-subdirectory-10.html
{
  /* <Route exact path="/avasCircle/build/" element={<Home />} />
or 
  <Route exact path="/" element={<Home />} />
  and 
    "homepage": "/avasCircle/build/", in or out of package.json
*/
}

function App() {
  return (
    <CartProvider>
      <div className="wrapper">
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            {/* <Route exact path="/avasCircle/build/" element={<Home />} /> */}
          </Routes>
        </Router>
      </div>
    </CartProvider>
  );
}

export default App;
