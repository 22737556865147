import { useState } from "react";
import OrderForm from "./OrderForm";

const Pricing = (props) => {
  const { itemName, deets, idx } = props;
  const [itemCount, updateItemCount] = useState(0);

  return (
    <div className="menu-content">
      <a>{!(0 === idx) || itemName}</a>

      <OrderForm
        qtyPrice={`(${deets.weight}) GHC${deets.price}`}
        count={itemCount}
        updateCount={updateItemCount}
        addItem={props.addItem}
        removeItem={props.removeItem}
      />
    </div>
  );
};

export default Pricing;
