import React, { Component } from "react";
import MenuItem from "./MenuItem";
import classes from "./Menu.module.css";
const MenuItems = (props) => {
  const items = [
    {
      id: 0,
      filterType: "meals",
      imageLink: "assets/img/menu/beef-potato.jpeg",
      itemName: "Beef & Potatoes",
      itemPrice: "25.00",
      itemPrices: [{ price: "25.00", weight: "800g" }],
      itemIngredients:
        "Minced meat, liver, peas, carrots, apples, fish oil, potatoes",
    },
    {
      id: 1,
      filterType: "meals",
      imageLink: "assets/img/menu/chicken-potato.jpeg",
      itemName: "Chicken & Potatoes",
      itemPrice: "25.00",
      itemPrices: [{ price: "25.00", weight: "800g" }],
      itemIngredients:
        "Deboned chicken, gizzards, carrots, spinach, eggs, squash, potatoes",
    },
    {
      id: 2,
      filterType: "meals",
      imageLink: "assets/img/menu/fish-potato.jpeg",
      itemName: "Fish & Potatoes",
      itemPrice: "25.00",
      itemPrices: [{ price: "25.00", weight: "800g" }],
      itemIngredients: "Deboned fish, carrots, cucumber, green beans, potatoes",
    },

    {
      id: 3,
      filterType: "sauces",
      imageLink: "assets/img/menu/beef-sauce.jpeg",
      itemName: "Beef Sauce",
      itemPrice: "30.00",
      itemPrices: [
        { price: "30.00", weight: "400g" },
        { price: "50.00", weight: "700g" },
      ],
      itemIngredients: "Minced meat, liver, peas, carrots, apples",
    },
    {
      id: 4,
      filterType: "sauces",
      imageLink: "assets/img/menu/chicken-sauce.jpeg",
      itemName: "Chicken Sauce",
      itemPrice: "30.00",
      itemPrices: [
        { price: "30.00", weight: "400g" },
        { price: "50.00", weight: "700g" },
      ],
      itemIngredients:
        "Deboned chicken, gizzard, carrots, eggs, spinach, squash",
    },
    {
      id: 5,
      filterType: "sauces",
      imageLink: "assets/img/menu/fish-sauce.jpeg",
      itemName: "Fish Sauce",
      itemPrice: "30.00",
      itemPrices: [
        { price: "30.00", weight: "400g" },
        { price: "50.00", weight: "700g" },
      ],
      itemIngredients: "Deboned fish, carrots, cucumber, green beans",
    },

    {
      id: 6,
      filterType: "treats",
      imageLink: "assets/img/menu/chicken-delight.jpeg",
      itemName: "Chicken Delight",
      itemPrice: "20.00",
      itemPrices: [{ price: "20.00", weight: "70g" }],
      itemIngredients: "Chicken breast",
    },
    {
      id: 7,
      filterType: "treats",
      imageLink: "assets/img/menu/liver-treats.jpeg",
      itemName: "Liver Bites",
      itemPrice: "20.00",
      itemPrices: [{ price: "20.00", weight: "70g" }],
      itemIngredients: "Liver",
    },
  ];
  return (
    <div
      key={props.id}
      className="row menu-container"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      {items.map((item) => (
        <MenuItem
          key={item.id}
          item={item}
          itemId={item.id}
          className={item.filterType}
          filterType={item.filterType}
          imageLink={item.imageLink}
          itemPrices={item.itemPrices}
          itemName={item.itemName}
          itemPrice={item.itemPrice}
          itemIngredients={item.itemIngredients}
        />
      ))}
    </div>
  );
};

class Menu extends Component {
  render() {
    return (
      <>
        {/*<!-- ======= Menu Section ======= -->*/}
        <section id="menu" className="menu section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Menu</h2>
              <p>Check Our Tasty Menu</p>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="menu-flters">
                  <li data-filter="*" className="filter-active">
                    All
                  </li>
                  <li data-filter=".filter-sauces">Sauces</li>
                  <li data-filter=".filter-treats">Treats</li>
                  <li data-filter=".filter-meals">Meals</li>
                </ul>
              </div>
            </div>

            <MenuItems id={0} />
          </div>
        </section>
        {/*<!-- End Menu Section -->*/}
      </>
    );
  }
}

export default Menu;
