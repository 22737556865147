import { useReducer } from "react";
import CartContext from "./cart-context";

const defaultCartState = {
  items: [],
  totalAmount: 0,
  totalItems: 0,
};

const cartReducer = (state, action) => {
  console.log(state, action);
  if ("ADD" === action.type) {
    const updatedItems = [...state.items];
    // const updatedItems = state.items.concat(action.item);
    const idx = updatedItems.findIndex(
      (item) => item.key === action.priceWeight.key
    );
    let item = updatedItems[idx];
    // console.log(item, item == true);
    if (item) {
      let amt = item.amount + 1;
      updatedItems[idx] = { ...item, amount: amt };
    } else {
      updatedItems.push({
        ...action.item,
        ...action.priceWeight,
        amount: 1,
        key: action.priceWeight.key,
      });
    }
    const updatedTotal = state.totalAmount - -action.priceWeight.price;
    const updateItemsTotal = state.totalItems + 1;
    updatedItems.sort((a, b) => a.id - b.id);
    return {
      items: updatedItems,
      totalAmount: updatedTotal,
      totalItems: updateItemsTotal,
    };
  } else if ("REMOVE" === action.type) {
    const updatedItems = [...state.items];
    const idx = updatedItems.findIndex(
      (item) => item.key === action.priceWeight.key
    );

    if (idx === -1) {
      return {
        items: updatedItems,
        totalAmount: state.totalAmount,
        totalItems: state.totalItems,
      };
    }
    const updateItemsTotal = state.totalItems - 1;
    const item = updatedItems[idx];
    const amt = item.amount - 1;
    updatedItems[idx] = { ...item, amount: amt };
    // console.log(updatedItems[idx].amount);
    // updatedItems[idx].amount = updatedItems[idx].amount - 1;
    // console.log(updatedItems[idx].amount);
    // console.log(updatedItems[idx]);
    const updatedTotal = state.totalAmount - action.priceWeight.price;
    if (1 > updatedItems[idx].amount) {
      updatedItems.splice(idx, 1);
    }
    // console.log(updatedItems[idx]);
    return {
      items: updatedItems,
      totalAmount: updatedTotal,
      totalItems: updateItemsTotal,
    };
  }
  return defaultCartState;
};

const CartProvider = (props) => {
  const [cartState, dispatchCartAction] = useReducer(
    cartReducer,
    defaultCartState
  );
  const addItemToCartHandler = (item, priceWeight) => {
    dispatchCartAction({ item: item, priceWeight: priceWeight, type: "ADD" });
  };
  const removeItemFromHadler = (item, priceWeight) => {
    dispatchCartAction({
      item: item,
      priceWeight: priceWeight,
      type: "REMOVE",
    });
  };
  const cartContext = {
    items: cartState.items,
    totalAmount: cartState.totalAmount,
    totalItems: cartState.totalItems,
    addItem: addItemToCartHandler,
    removeItem: removeItemFromHadler,
  };
  return (
    <CartContext.Provider value={cartContext}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartProvider;
